import React, {Component} from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import {Dropdown, DropdownOptionProps, DropdownProps} from 'wix-ui-tpa/Dropdown';
import {ColorPicker} from 'wix-ui-tpa/ColorPicker';
import {RadioButtonChangeEvent, RadioButtonClickEvent} from 'wix-ui-core/src/components/radio-button/RadioButton';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productOptions/ProductVariantCalculator';
import s from './ProductOptions.scss';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/src/graphql-schema';

export interface IProductOptionsProps {
  product: IProduct;
  selectionIds: number[];
  onSelectionIdsChange(optionSelections: IProductOptionsProps['selectionIds']): void;
  variantsAvailability: IProductSelectionAvailabilityMap;
}

interface OptionRenderParams {
  option: IProduct['options'][number];
  availability: IProductSelectionAvailabilityMap[string];
}

export enum DataHook {
  ProductOption = 'product-option',
  DropdownOption = 'product-options-dropdown',
  ColorOption = 'product-options-color',
}

export class ProductOptions extends Component<IProductOptionsProps> {
  private readonly getAllVisibleDropdownOptions = () => {
    const colorOptsCount = this.getAllVisibleColorOptions().length;

    const slotsForDropdowns = 2 - colorOptsCount;

    if (slotsForDropdowns <= 0) {
      return [];
    }

    return this.props.product.options
      .filter(({optionType}) => optionType === ProductOptionType.DROP_DOWN)
      .slice(0, slotsForDropdowns);
  };

  private readonly getAllVisibleColorOptions = () => {
    return this.props.product.options.filter(({optionType}) => optionType === ProductOptionType.COLOR).slice(0, 2);
  };

  private readonly sealColorChangeHandler = (optionsSelections: OptionRenderParams['option']['selections']) => (
    event: RadioButtonChangeEvent | RadioButtonClickEvent
  ) => {
    const {selectionIds, onSelectionIdsChange} = this.props;

    const pickedSelectionId = optionsSelections.find(({value}) => value === event.value).id;

    const nextSelectionIds = [
      ...selectionIds.filter((selectionId) => !optionsSelections.find(({id}) => id === selectionId)),
      pickedSelectionId,
    ];

    onSelectionIdsChange(nextSelectionIds);
  };

  private readonly sealDropdownChangeHandler = (optionsSelectionIds: number[]): DropdownProps['onChange'] => (
    selected
  ) => {
    const {selectionIds, onSelectionIdsChange} = this.props;

    const nextSelectionIds = [
      ...selectionIds.filter((selectionId) => !optionsSelectionIds.includes(selectionId)),
      parseInt(selected.id, 10),
    ];

    onSelectionIdsChange(nextSelectionIds);
  };

  private readonly renderColorOption = ({option: {id, selections}, availability}: OptionRenderParams) => {
    const {selectionIds} = this.props;

    const colorItems = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => (
        <ColorPicker.Item
          key={selection.id}
          aria-label={selection.description}
          value={selection.value}
          disabled={!availability[selection.id].isSelectable}
          checked={selectionIds.includes(selection.id)}
        />
      ));

    return (
      <div key={id} data-hook={DataHook.ProductOption}>
        <ColorPicker onChange={this.sealColorChangeHandler(selections)} data-hook={DataHook.ColorOption}>
          {colorItems}
        </ColorPicker>
      </div>
    );
  };

  private readonly renderDropdownOption = ({option: {id, title, selections}, availability}: OptionRenderParams) => {
    const options: DropdownOptionProps[] = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: availability[selection.id].isSelectable,
        value: selection.description,
      }));

    return (
      <div data-hook={DataHook.ProductOption} key={id}>
        <Dropdown
          data-hook={DataHook.DropdownOption}
          placeholder={title}
          options={options}
          onChange={this.sealDropdownChangeHandler(selections.map((selection) => selection.id))}
        />
      </div>
    );
  };

  private readonly rendrAllColorOptions = () => {
    const colorOptions = this.getAllVisibleColorOptions();

    const {variantsAvailability} = this.props;

    return colorOptions.map((option) => (
      <div key={option.id} className={s.color}>
        {this.renderColorOption({option, availability: variantsAvailability[option.id]})}
      </div>
    ));
  };

  private readonly renderAllDropdownOptions = () => {
    const dropDownOptions = this.getAllVisibleDropdownOptions();

    const {variantsAvailability} = this.props;

    return dropDownOptions.map((option) => (
      <div key={option.id} className={s.dropdown}>
        {this.renderDropdownOption({option, availability: variantsAvailability[option.id]})}
      </div>
    ));
  };

  public render() {
    return (
      <>
        {this.rendrAllColorOptions()}
        {this.renderAllDropdownOptions()}
      </>
    );
  }
}
