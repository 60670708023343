/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa/Dropdown';
import {classes, st} from './Sort.st.css';
import s from './Sort.scss';
import {Accept} from '../../../icons/dist';
import {sortOptions} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export enum DataHook {
  SelectedIOptionIcon = 'selected-option-icon',
}

export enum DataHook {
  SortDropdown = 'sort-dropdown',
}

@withGlobals
@withTranslations()
export class Sort extends React.Component<IGalleryGlobalProps & IProvidedTranslationProps> {
  private readonly getOptions = () => {
    const {selectedSort, sortingOptions} = this.props.globals;

    return sortingOptions.map((o) => ({
      isDisabled: false,
      isSelectable: true,
      id: o.id,
      value: ((
        <div tabIndex={0} className={s.selectorItem}>
          <span>{this.getOptionText(o)}</span>
          {selectedSort.id === o.id && (
            <Accept className={classes.selectedItemIcon} data-hook={DataHook.SelectedIOptionIcon} />
          )}
        </div>
      ) as unknown) as string,
    }));
  };

  private readonly getOptionText = (o) => {
    return this.props.t(o.titleKey);
  };

  private readonly onSelect = (option: DropdownOptionProps) => {
    const sortingOption = sortOptions.find((o) => o.id === option.id);
    this.props.globals.sortProducts(sortingOption);
  };

  private readonly onExpandedChange = (isExpanded) => {
    if (!isExpanded) {
      return;
    }

    this.props.globals.sendSortClickBiEvent();
  };

  public render = () => {
    const {selectedSort} = this.props.globals;

    return (
      <div>
        <Dropdown
          onExpandedChange={this.onExpandedChange}
          data-hook={DataHook.SortDropdown}
          className={st(classes.sort, s.selector)}
          initialSelectedId={selectedSort.id}
          // initialSelectedId="default"
          onChange={this.onSelect}
          placement={'bottom'}
          options={this.getOptions()}
          upgrade
        />
      </div>
    );
  };
}
